@import "./../node_modules/@ng-select/ng-select/themes/default.theme.css";
// global styles
body {
  color: var(--text-color);
  background-color: var(--background-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin-bottom: 0;
}

.custom-card {
  padding: 0.625rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  background: var(--white-color) 0 0 no-repeat padding-box;
}

.overflow-text {
  overflow: hidden;
  max-width: 9.5rem;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}

.cursor {
  cursor: pointer;
}

// skeleton styles
@keyframes p-skeleton-animation {
  0% {
    transform: translateX(-100%)
  }
  to {
    transform: translateX(100%)
  }
}

.skeleton {
  overflow: hidden;

  .skeleton-effect {
    position: relative;

    &:after {
      content: "";
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      height: 100%;
      position: absolute;
      transform: translateX(-100%);
      animation: p-skeleton-animation 1.2s infinite;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
    }
  }

  .p-mb-3 {
    margin-bottom: 1rem;
  }

  .p-d-flex {
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .circle {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 8px;
    background-color: #e9ecef;
  }

  .custom-skeleton {
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 0.655rem;
    background: var(--white-color);
  }

  .stripes {
    div {
      width: 14rem;
      height: 1.25rem;
      border-radius: 0;
      margin-bottom: 0.5rem;
      background-color: #e9ecef;
    }

    div:last-child {
      margin-bottom: 0;
    }
  }

  .fullStripes {
    div {
      width: 100%;
      margin-bottom: 0.5rem;
    }

    div:first-child {
      height: 2rem;
      margin: auto auto 1rem;
    }
  }

  .full2Stripes {
    div {
      width: 100%;
    }

    div:first-child {
      width: 100%;
      height: 3.75rem;
      margin-bottom: 1rem;
    }
  }

  .fullEmpStripes {
    div {
      width: 100%;
    }
  }
}

// ng-select
.ng-select {
  .ng-select-container {
    border: none;
    font-size: 10px;
    min-height: 1.5rem;
    border-radius: 0;
    border-bottom: 1px solid var(--primary-color) !important;
  }

  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option {
        font-size: 10px;
        padding: 0.25rem 0.625rem;
      }

      .ng-option.ng-option-marked {
        background-color: var(--background-color);
      }
    }

    .ng-arrow-wrapper .ng-arrow {
      border-color: var(--primary-color) transparent transparent;
    }
  }

  &.ng-select-opened > .ng-select-container .ng-arrow {
    border-color: transparent transparent var(--primary-color);
  }

  .ng-arrow-wrapper {
    top: 2px;
    color: var(--primary-color);
  }

  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          display: flex;
          font-size: 10px;
          flex-direction: row-reverse;
          background-color: var(--background-color);

          .ng-value-icon.left {
            border-right: 1px solid transparent;
            border-left: 1px solid var(--background-color);
          }
        }
      }
    }
  }

  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    box-shadow: none;
    border-color: transparent;
  }
}

// toast messages
.saved-list {
  position: absolute;
  top: 2.625rem;
  z-index: 9999;
  display: flex;
  right: 0.625rem;
  border-radius: 6px;
  max-width: 18.75rem;
  align-items: center;
  min-width: 11.25rem;
  box-shadow: 0 0 6px #00000050;
  background: var(--white-color);
  justify-content: space-between;
  padding: 0.25rem 0.625rem 0.25rem 0.625rem;

  .saved-list-left {
    img {
      width: 1rem;
    }

    strong {
      font-size: 12px;
    }
  }

  .saved-list-right {
    i {
      font-size: 12px;
      cursor: pointer;
    }
  }
}

// tooltip
.tooltip {
  font-size: 10px;
  padding: 3px 5px !important;
}

// scrollbar css
*::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

*::-webkit-scrollbar-thumb {
  background: #d8dcde;
  border-radius: 10px;
}

// modal
.pop-up {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: scale(1.1);
  background-color: rgba(0, 0, 0, 0.5);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;

  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 12px;
    width: 12.875rem;
    border-radius: 0.5rem;
    background-color: white;
    transform: translate(-50%, -50%);

    .modal-title {
      font-size: 14px;
      line-height: normal;
    }

    .modal-desc {
      p {
        font-size: 12px;
        margin-top: 0.25rem;
        font-weight: normal;
        line-height: normal;
      }
    }

    .modal-form {
      label {
        font-size: 12px;
        font-weight: 600;
        line-height: normal;
      }

      .form-control {
        font-size: 12px;

        &:focus {
          box-shadow: none;
          outline: none;
          border-color: var(--border-color);
        }
      }
    }

    .action-btn {
      gap: 0.25rem;
      display: flex;
      margin-top: 1rem;
      align-items: center;
      justify-content: flex-end;

      .custom-btn {
        cursor: pointer;
        font-size: 12px;
        padding: 2px 5px;
        border-radius: 3px;
        text-align: center;
        min-width: 3.125rem;
        background-color: var(--white-color);
        border: 1px solid var(--primary-color);

        &.fill {
          background-color: var(--primary-color);
          color: var(--white-color);
        }

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }

  .show-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  }
}

// custom select
.custom-select {
  position: relative;
  width: 100%;
  font-size: 12px;
  margin: 0.625rem 0;
  max-width: 18.75rem;

  .selected-option {
    position: relative;
    display: flex;
    cursor: pointer;
    align-items: center;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    justify-content: space-between;
    background-color: var(--white-color);
    border: 1px solid var(--border-color);
    transition: box-shadow 0.15s ease-in-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);

    &::after {
      content: '\2304';
      position: absolute;
      right: 0;
      top: 40%;
      font-size: 14px;
      font-weight: 600;
      margin-left: auto;
      padding-left: 10px;
      transform: translate(-50%, -50%);
    }

    &.open {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.175);

      &:after {
        content: '\2303';
        top: 55%;
      }
    }
  }

  .options {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 1;
    width: 100%;
    display: none;
    background-color: var(--white-color);
    border: 1px solid var(--border-color);

    &.open {
      display: block;
    }

    .option {
      cursor: pointer;
      padding: 0.25rem 0.75rem;
      transition: background-color 0.15s ease-in-out;

      &:hover {
        background-color: var(--white-color);
      }
    }
  }
}

// ng-select custom style
.action {
  gap: 1.25rem;
  display: flex;
  height: 1.25rem;
  align-items: center;
  justify-content: flex-start;

  .add-to-list {
    position: relative;

    .save-contact {
      position: absolute;
      right: 0;
      top: 1.5rem;
      z-index: 999;
      width: 12.5rem;
      border-radius: 2px;
      background: var(--white-color);
      border: 1px solid var(--primary-color);

      .action-buttons {
        padding: 2px 0 5px 5px;

        span {
          font-size: 10px;
          font-weight: 600;
          padding: 2px 5px;
          cursor: pointer;
          margin-right: 0.25rem;
          border-radius: 0.25rem;
          color: var(--primary-color);
          border: 1px solid var(--primary-color);
        }

        .fill-btn {
          color: var(--white-color);
          background: var(--primary-color);
        }
      }
    }
  }

  img {
    width: 1rem;
    cursor: pointer;
  }

  &.no-cursor {
    img {
      cursor: default;
      filter: grayscale(100%) !important;
    }
  }
}
