// Theme Implementation
// --red-color
// --tag-color
// --text-color
// --white-color
// --green-color
// --hover-color
// --primary-svg
// --border-color
// --shadow-color
// --secondary-svg
// --primary-color
// --secondary-color
// --background-color
// --gradient-background

/* Tag Styles */
body {
  color: var(--text-color) !important;
  background-color: var(--background-color) !important;
}

h2,
h3,
h4,
h5,
h6 {
  color: var(--text-color) !important;
}

p,
label {
  color: var(--text-color) !important;
}

.vts-svg-primary {
  filter: var(--primary-svg) !important;
}

.vts-svg-secondary {
  filter: var(--secondary-svg) !important;
}

.vts-theme-button {
  color: var(--white-color) !important;
  box-shadow: var(--shadow-color) !important;
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

.vts-theme-bg {
  background-color: var(--primary-color) !important;
}

.vts-background-color {
  background: var(--background-color) !important;
}

.vts-icon-color {
  color: var(--primary-color) !important;
}

.vts-tag-color {
  span {
    color: var(--tag-color) !important;
  }
}

.vts-tag-color {
  color: var(--tag-color) !important;
}

.vts-chipset {
  color: var(--text-color) !important;
  border-color: var(--text-color) !important;
}

footer nav .nav-item.active {
  border-color: var(--primary-color) !important;
}

.vts-green-color-bg {
  background-color: var(--green) !important;
}

.vts-green-color-border {
  ul.viewed {
    li {
      border-color: var(--green) !important;
    }
  }
}

.vts-green {
  border-color: var(--green) !important;
  background-color: var(--green) !important;
}

.vts-red-color-bg {
  background-color: var(--red) !important;
}

.vts-inputText {
  border-color: var(--primary-color) !important;
}

input:checked + .slider {
  background-color: var(--primary-color) !important;
}

.vts-border-theme {
  border-color: var(--primary-color) !important;
}

.vts-hover-color {
  &:hover {
    color: var(--primary-color) !important;
    background: var(--background-color) !important;
  }
}

.ng-select .ng-select-container {
  border-color: var(--primary-color) !important;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow, .ng-select .ng-arrow-wrapper {
  color: var(--primary-color) !important;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: transparent transparent var(--primary-color) !important;
}

.vts-text-box {
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.vts-gradient-background {
  background: var(--gradient-background) !important;
}
